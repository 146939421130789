input,
textarea,
button {
  font-size: inherit;
  font-family: inherit;
  appearance: none;
  border: 0;
  color: inherit;
  border-radius: 0;
  background: none;
  padding: 0;

  @include placeholder {
    color: var(--dark40);
  }
}
