// settings
$font-fixed: Consolas, inherit;
$font-sans-serif: inherit;
$z-toolbar: 2000 !default;

// animations/image-loading
@keyframes medium-editor-image-loading {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

// animations/pop-upwards
@keyframes medium-editor-pop-upwards {
  0% {
    opacity: 0;
    transform: matrix(0.97, 0, 0, 1, 0, 12);
  }

  20% {
    opacity: 0.7;
    transform: matrix(0.99, 0, 0, 1, 0, 2);
  }

  40% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, -1);
  }

  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

// components/anchor-preview
.medium-editor-anchor-preview {
  font-family: $font-sans-serif;
  font-size: 16px;
  left: 0;
  line-height: 1.4;
  max-width: 280px;
  position: absolute;
  text-align: center;
  top: 0;
  word-break: break-all;
  word-wrap: break-word;
  visibility: hidden;
  z-index: $z-toolbar;

  a {
    color: #fff;
    display: inline-block;
    margin: 11px 18px;
  }
}

.medium-editor-anchor-preview-active {
  visibility: visible;
}

// components/file-dragging
.medium-editor-dragover {
  background: #ddd;
}

.medium-editor-image-loading {
  animation: medium-editor-image-loading 1s infinite ease-in-out;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  height: 40px;
  width: 40px;
}

// components/placeholder
.medium-editor-placeholder {
  position: relative;

  &:after {
    content: attr(data-placeholder) !important;
    // position: absolute;
    // left: 0;
    // top: 0;
    padding: inherit;
    margin: inherit;
  }
}

.medium-editor-placeholder-relative {
  position: relative;

  &:after {
    content: attr(data-placeholder) !important;
    font-style: italic;
    position: relative;
    white-space: pre;
    padding: inherit;
    margin: inherit;
  }
}

// components/toolbar
%medium-toolbar-arrow {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  left: 50%;
  margin-left: -8px;
  position: absolute;
  width: 0;
}

.medium-toolbar-arrow-under:after {
  @extend %medium-toolbar-arrow;
  border-width: 8px 8px 0 8px;
}

.medium-toolbar-arrow-over:before {
  @extend %medium-toolbar-arrow;
  border-width: 0 8px 8px 8px;
  top: -8px;
}

.medium-editor-toolbar {
  font-family: $font-sans-serif;
  font-size: 16px;
  left: 0;
  position: absolute;
  top: 0;
  visibility: hidden;
  z-index: $z-toolbar;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;

    button {
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      font-size: 14px;
      line-height: 1.33;
      margin: 0;
      padding: 11px 18px;
      text-decoration: none;

      &:focus {
        outline: none;
      }
    }

    .medium-editor-action-underline {
      text-decoration: underline;
    }

    .medium-editor-action-pre {
      font-family: $font-fixed;
      font-size: 12px;
      font-weight: 100;
      padding: 15px 0;
    }
  }
}

.medium-editor-toolbar-active {
  visibility: visible;
}

.medium-editor-sticky-toolbar {
  position: fixed;
  top: 1px;
}

.medium-editor-relative-toolbar {
  position: relative;
}

.medium-editor-toolbar-active.medium-editor-stalker-toolbar {
  animation: medium-editor-pop-upwards 160ms forwards linear;
}

.medium-editor-toolbar-actions {
  @extend %clearfix;
}

.medium-editor-action-bold {
  font-weight: bolder;
}

.medium-editor-action-italic {
  font-style: italic;
}

// components/toolbar-form

.medium-editor-toolbar-form {
  display: none;

  input,
  a {
    font-family: $font-sans-serif;
  }

  .medium-editor-toolbar-form-row {
    line-height: 14px;
    margin-left: 5px;
    padding-bottom: 5px;
  }

  .medium-editor-toolbar-input,
  label {
    border: none;
    box-sizing: border-box;
    font-size: 14px;
    margin: 0;
    padding: 6px;
    width: 316px;
    display: inline-block;

    &:focus {
      appearance: none;
      border: none;
      box-shadow: none;
      outline: 0;
    }
  }

  a {
    display: inline-block;
    font-size: 24px;
    font-weight: bolder;
    margin: 0 10px;
    text-decoration: none;
  }
}

.medium-editor-toolbar-form-active {
  display: block;
}
// util/clearfix
%clearfix {
  &:after {
    clear: both;
    content: '';
    display: table;
  }
}

// contenteditable rules
.medium-editor-element {
  word-wrap: break-word;
  min-height: 30px;

  img {
    max-width: 100%;
  }

  sub {
    vertical-align: sub;
  }

  sup {
    vertical-align: super;
  }
}

.medium-editor-hidden {
  display: none;
}

// theme

// theme settings
$medium-editor-bgcolor: $dark;
$medium-editor-button-size: 2rem;
$medium-editor-border-radius: 6px;

// theme rules
.medium-toolbar-arrow-under:after {
  border-color: $medium-editor-bgcolor transparent transparent transparent;
  top: $medium-editor-button-size;
}

.medium-toolbar-arrow-over:before {
  border-color: transparent transparent $medium-editor-bgcolor transparent;
  top: -8px;
}

.medium-editor-toolbar {
  background-color: $medium-editor-bgcolor;
  border-radius: $medium-editor-border-radius;
  box-shadow:
    0 0 4px rgba(#000, 0.15),
    0 8px 10px -10px rgba(#000, 0.6);

  li {
    button {
      background-color: $medium-editor-bgcolor;
      border: 0;
      color: #fff;
      height: $medium-editor-button-size;
      min-width: $medium-editor-button-size;
      transition: background-color 0.2s ease-in;

      &:hover {
        background-color: darken($dark, 5%);
        color: #fff;
      }
    }

    .medium-editor-button-first {
      border-bottom-left-radius: $medium-editor-border-radius;
      border-top-left-radius: $medium-editor-border-radius;
    }

    .medium-editor-button-last {
      border-bottom-right-radius: $medium-editor-border-radius;
      border-top-right-radius: $medium-editor-border-radius;
    }

    .medium-editor-button-active {
      background-color: $purple;
      color: #fff;
    }
  }
}

.medium-editor-toolbar-form {
  background: $medium-editor-bgcolor;
  border-radius: $medium-editor-border-radius;
  color: #999;

  .medium-editor-toolbar-input {
    background: $medium-editor-bgcolor;
    box-sizing: border-box;
    color: #ccc;
    height: $medium-editor-button-size;
  }

  a {
    color: #fff;
  }
}

.medium-editor-toolbar-anchor-preview {
  background: $medium-editor-bgcolor;
  border-radius: $medium-editor-border-radius;
  color: #fff;
}

.medium-editor-placeholder:after {
  color: #b3b3b1;
}
