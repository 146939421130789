h1,
h2,
.h1,
.h2 {
}

// block elements
h1,
.h1 {
}

h2,
.h2 {
}

h3,
.h3 {
  font-weight: 500;
  font-size: 1.7em;
  margin-bottom: 0.75em;

  @media (min-width: 900px) {
    font-size: 1.75em;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

h4,
.h4 {
  font-weight: bold;
  font-size: 1.1em;
  line-height: 1.3em;
  margin-bottom: 0.5em;

  &:last-child {
    margin-bottom: 0;
  }
}

h5,
.h5 {
  font-size: 0.9em;
  letter-spacing: 0.015em;
  font-weight: 500;
}

h6,
.h6 {
  font-size: 0.85em;
  letter-spacing: 0.015em;
}

// inline
a {
  text-decoration: none;
  color: inherit;
}

strong,
b {
  font-weight: bold;
}

em,
i {
  font-style: italic;
}

u {
  @include underline(currentColor, 0.125em, 100%);
}

// paragraph
p,
.p,
pre,
ol,
ul,
table {
  line-height: 1.5em;
  margin-bottom: 1.5em;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
    color: var(--color, #{$highlight});
    @include underline(currentColor, 0.125em, 100%);
    transition: color $speed * 0.5 $ease;
  }
}

pre {
  width: 100%;
  word-wrap: break-word;
  clear: left;
  white-space: pre-wrap;
}

// quotes

blockquote {
}

.blockquote {
  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
  }
}

figcaption {
  line-height: 1.5em;
  font-size: 0.7em;
  color: lighten(#000, 40%);
  padding: calc(var(--padding) * 0.5) var(--padding) 0;
  margin: 0 auto;
  max-width: calc(28rem + (var(--padding) * 6));

  @media (min-width: 900px) {
    padding: calc(var(--padding) * 0.5) 0 0;
  }
}

// small

small,
.small {
}

.caps {
  font-variant: small-caps;
}

// align

.align {
  &--left {
    text-align: left;
  }
  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
  }
}
