// basis grotesque
@font-face {
  font-family: 'Basis';
  src:
    url('../../fonts/basis-grotesque-regular.woff2') format('woff2'),
    url('../../fonts/basis-grotesque-regular.woff') format('woff');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Basis';
  src:
    url('../../fonts/basis-grotesque-italic.woff2') format('woff2'),
    url('../../fonts/basis-grotesque-italic.woff') format('woff');
  font-weight: normal;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Basis';
  src:
    url('../../fonts/basis-grotesque-medium.woff2') format('woff2'),
    url('../../fonts/basis-grotesque-medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Basis';
  src:
    url('../../fonts/basis-grotesque-bold.woff2') format('woff2'),
    url('../../fonts/basis-grotesque-bold.woff') format('woff');
  font-weight: bold;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Basis';
  src:
    url('../../fonts/basis-grotesque-bold-italic.woff2') format('woff2'),
    url('../../fonts/basis-grotesque-bold-italic.woff') format('woff');
  font-weight: bold;
  font-display: swap;
  font-style: italic;
}

// mono

// @font-face {
//     font-family: 'BasisMono';
//     src: url('../../fonts/basis-grotesque-mono-regular.woff2') format('woff2'),
//         url('../../fonts/basis-grotesque-mono-regular.woff') format('woff');
//     font-weight: normal;
//     font-display: swap;
//     font-style: normal;
// }

@font-face {
  font-family: 'BasisMono';
  src:
    url('../../fonts/basis-grotesque-mono-medium.woff2') format('woff2'),
    url('../../fonts/basis-grotesque-mono-medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

// fortescue
@font-face {
  font-family: 'Fortescue';
  src: url('../../fonts/fortescue-bold.eot');
  src:
    url('../../fonts/fortescue-bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/fortescue-bold.woff2') format('woff2'),
    url('../../fonts/fortescue-bold.woff') format('woff');
  font-weight: bold;
  font-display: swap;
  font-style: normal;
}

// terrazzo
@font-face {
  font-family: 'Terrazzo';
  src:
    url('../../fonts/Terrazzo-Regular_web.woff2') format('woff2'),
    url('../../fonts/Terrazzo-Regular_web.woff') format('woff');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

// charter
@font-face {
  font-family: 'Charter';
  src: url('../../fonts/35EC1A_0_0.eot');
  src:
    url('../../fonts/35EC1A_0_0.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/35EC1A_0_0.woff2') format('woff2'),
    url('../../fonts/35EC1A_0_0.woff') format('woff');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Charter';
  src: url('../../fonts/35EC1A_1_0.eot');
  src:
    url('../../fonts/35EC1A_1_0.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/35EC1A_1_0.woff2') format('woff2'),
    url('../../fonts/35EC1A_1_0.woff') format('woff');
  font-weight: normal;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Charter';
  src: url('../../fonts/35EC1A_2_0.eot');
  src:
    url('../../fonts/35EC1A_2_0.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/35EC1A_2_0.woff2') format('woff2'),
    url('../../fonts/35EC1A_2_0.woff') format('woff');
  font-weight: bold;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Charter';
  src: url('../../fonts/35EC1A_3_0.eot');
  src:
    url('../../fonts/35EC1A_3_0.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/35EC1A_3_0.woff2') format('woff2'),
    url('../../fonts/35EC1A_3_0.woff') format('woff');
  font-weight: bold;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'CSIcons';
  src: url('../../fonts/Icons-Regular.eot');
  src:
    url('../../fonts/Icons-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Icons-Regular.woff2') format('woff2'),
    url('../../fonts/Icons-Regular.woff') format('woff');
  font-weight: normal;
  font-display: block;
  font-style: normal;
}
