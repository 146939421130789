@import '../utilities';
@import '../variables';
@import '../common';

:root {
  // colors
  --black: #000;
  --white: #fff;
  --white-slant: #f8f6f3;
  --grey: #647275;
  --lightgrey: #f8f6f3;
  --blue: #2c7efc;
  --darkmode: #070707;

  --purple: #311d51;
  --highlight: #6832ef;
  --support: #f8f6f3;

  // base
  --selection-color: $highlight;
  --font-color: $black;
  --padding: 2rem;
  --font-family: 'Basis', Arial, sans-serif;
  --title-font-family: 'Fortescue', Times, serif;
  --text-font-family: 'Charter', Times, serif;
  --extra-font-family: 'Terrazzo', Arial, sans-serif;
  --border-radius: 0.6em;

  // sizes
  --size-xs: 568px;
  --size-md: 900px;
  --size-lg: 1250px;
  --size-xl: 1600px;

  --max-width: 56rem;

  // transition
  --speed: 0.25s;
  --ease: ease;
  --font-size: 18px;
  --padding: 1.35rem;
  --spadding: 2em;
  --vpadding: 2.5rem;

  @media (min-width: 374px) {
    --font-size: 20px;
    --padding: 1.5rem;
  }

  @media (min-width: 900px) {
    --vpadding: 3.5rem;
    --font-size: 22px;
  }
}
