*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: 0;
}

::selection {
  background: var(--color, $selection-color);
}

::-moz-selection {
  background: var(--color, $selection-color);
}

body,
html {
  font: $font-size $font-family;
  font: var(--font-size) $font-family;
  scroll-behavior: smooth;
}

body {
  background: $white;
  overflow-x: hidden;

  &.darkmode {
    color: $white;
    background: $darkmode;
  }
}

hr {
  margin: var(--vpadding) var(--padding);
  border: 0;
  height: 1px;
  background: currentColor;
  opacity: 0.05;

  @at-root .darkmode & {
    opacity: 0.1;
  }

  @media (min-width: 900px) {
    width: 28em;
    margin-left: auto;
    margin-right: auto;
  }
}
