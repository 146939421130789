*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: 0;
}

select,
input,
textarea {
  font-feature-settings: var(--font-settings);
}

::selection {
  background: $selection-color;
}

::-moz-selection {
  background: $selection-color;
}

body,
html {
  font: $font-size $font-family;
  background: $white;
  color: $dark;
  height: 100%;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: var(--font-settings);
}

.layout {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 1 auto;
  width: 100%;
}
