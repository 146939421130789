@use 'sass:math';

.grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex: 0 1 auto;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-left: math.div($padding, 3);
  padding-right: math.div($padding, 3);

  @media (min-width: 900px) {
    padding-left: $padding * 0.5;
    padding-right: $padding * 0.5;
  }

  & & {
    padding-left: 0;
    padding-right: 0;
  }

  @at-root .no-flexbox {
    @include clearfix;
  }
}

[class*='col-'] {
  flex: 0 0 auto;
  padding-left: math.div($padding, 3);
  padding-right: math.div($padding, 3);

  @media (min-width: 900px) {
    padding-left: $padding * 0.5;
    padding-right: $padding * 0.5;
  }

  @at-root .no-flexbox & {
    float: left;
  }
}

// columns
.col-1-1 {
  flex-basis: percentage(math.div(1, 1));
  width: percentage(math.div(1, 1));
  max-width: percentage(math.div(1, 1));
}
.col-2-3 {
  flex-basis: percentage(math.div(2, 3));
  width: percentage(math.div(2, 3));
  max-width: percentage(math.div(2, 3));
}
.col-1-3 {
  flex-basis: percentage(math.div(1, 3));
  width: percentage(math.div(1, 3));
  max-width: percentage(math.div(1, 3));
}
.col-1-2 {
  flex-basis: percentage(1 * 0.5);
  width: percentage(1 * 0.5);
  max-width: percentage(1 * 0.5);
}
.col-1-4 {
  flex-basis: percentage(1 * 0.25);
  width: percentage(1 * 0.25);
  max-width: percentage(1 * 0.25);
}
.col-3-4 {
  flex-basis: percentage(3 * 0.25);
  width: percentage(3 * 0.25);
  max-width: percentage(3 * 0.25);
}
.col-1-5 {
  flex-basis: percentage(1 * 0.2);
  width: percentage(1 * 0.2);
  max-width: percentage(1 * 0.2);
}
.col-1-6 {
  flex-basis: percentage(math.div(1, 6));
  width: percentage(math.div(1, 6));
  max-width: percentage(math.div(1, 6));
}
.col-2-5 {
  flex-basis: percentage(2 * 0.2);
  width: percentage(2 * 0.2);
  max-width: percentage(2 * 0.2);
}
.col-4-5 {
  flex-basis: percentage(4 * 0.2);
  width: percentage(4 * 0.2);
  max-width: percentage(4 * 0.2);
}
.col-1-5 {
  flex-basis: percentage(1 * 0.2);
  width: percentage(1 * 0.2);
  max-width: percentage(1 * 0.2);
}
.col-1-8 {
  flex-basis: 12.5%;
  width: percentage(1 * 0.125);
  max-width: percentage(1 * 0.125);

  @include xs {
    flex-basis: percentage(1 * 0.125);
    width: percentage(1 * 0.125);
    max-width: percentage(1 * 0.125);
  }
}

@include xs {
  .col-xs-1-1 {
    flex-basis: percentage(math.div(1, 1));
    width: percentage(math.div(1, 1));
    max-width: percentage(math.div(1, 1));
  }
  .col-xs-2-3 {
    flex-basis: percentage(math.div(2, 3));
    width: percentage(math.div(2, 3));
    max-width: percentage(math.div(2, 3));
  }
  .col-xs-1-3 {
    flex-basis: 33.33%;
    width: percentage(math.div(1, 3));
    max-width: percentage(math.div(1, 3));
  }
  .col-xs-1-2 {
    flex-basis: 50%;
    width: percentage(1 * 0.5);
    max-width: percentage(1 * 0.5);
  }
  .col-xs-1-4 {
    flex-basis: 25%;
    width: percentage(1 * 0.25);
    max-width: percentage(1 * 0.25);
  }
  .col-xs-1-8 {
    flex-basis: 12.5%;
    width: percentage(1 * 0.125);
    max-width: percentage(1 * 0.125);
  }
}

@include sm {
  .col-sm-1-1 {
    flex-basis: percentage(math.div(1, 1));
    width: percentage(math.div(1, 1));
    max-width: percentage(math.div(1, 1));
  }
  .col-sm-2-3 {
    flex-basis: percentage(math.div(2, 3));
    width: percentage(math.div(2, 3));
    max-width: percentage(math.div(2, 3));
  }
  .col-sm-1-3 {
    flex-basis: percentage(math.div(1, 3));
    width: percentage(math.div(1, 3));
    max-width: percentage(math.div(1, 3));
  }
  .col-sm-1-2 {
    flex-basis: percentage(1 * 0.5);
    width: percentage(1 * 0.5);
    max-width: percentage(1 * 0.5);
  }
  .col-sm-1-4 {
    flex-basis: 25%;
    width: percentage(1 * 0.25);
    max-width: percentage(1 * 0.25);
  }
  .col-sm-3-4 {
    flex-basis: 75%;
    width: percentage(3 * 0.25);
    max-width: percentage(3 * 0.25);
  }
  .col-sm-1-5 {
    flex-basis: percentage(1 * 0.2);
    width: percentage(1 * 0.2);
    max-width: percentage(1 * 0.2);
  }
  .col-sm-4-5 {
    flex-basis: percentage(4 * 0.2);
    width: percentage(4 * 0.2);
    max-width: percentage(4 * 0.2);
  }
  .col-sm-1-8 {
    flex-basis: 12.5%;
    width: percentage(1 * 0.125);
    max-width: percentage(1 * 0.125);
  }
}

@media (min-width: 900px) {
  .col-md-1-1 {
    flex-basis: percentage(math.div(1, 1));
    width: percentage(math.div(1, 1));
    max-width: percentage(math.div(1, 1));
  }
  .col-md-2-3 {
    flex-basis: percentage(math.div(2, 3));
    width: percentage(math.div(2, 3));
    max-width: percentage(math.div(2, 3));
  }
  .col-md-1-3 {
    flex-basis: percentage(math.div(1, 3));
    width: percentage(math.div(1, 3));
    max-width: percentage(math.div(1, 3));
  }
  .col-md-1-2 {
    flex-basis: percentage(1 * 0.5);
    width: percentage(1 * 0.5);
    max-width: percentage(1 * 0.5);
  }
  .col-md-1-4 {
    flex-basis: percentage(1 * 0.25);
    width: percentage(1 * 0.25);
    max-width: percentage(1 * 0.25);
  }
  .col-md-3-4 {
    flex-basis: percentage(3 * 0.25);
    width: percentage(3 * 0.25);
    max-width: percentage(3 * 0.25);
  }
  .col-md-1-5 {
    flex-basis: percentage(1 * 0.2);
    width: percentage(1 * 0.2);
    max-width: percentage(1 * 0.2);
  }
  .col-md-1-6 {
    flex-basis: percentage(math.div(1, 6));
    width: percentage(math.div(1, 6));
    max-width: percentage(math.div(1, 6));
  }
  .col-md-2-5 {
    flex-basis: percentage(2 * 0.2);
    width: percentage(2 * 0.2);
    max-width: percentage(2 * 0.2);
  }
  .col-md-3-5 {
    flex-basis: percentage(3 * 0.2);
    width: percentage(3 * 0.2);
    max-width: percentage(3 * 0.2);
  }
  .col-md-1-8 {
    flex-basis: percentage(1 * 0.125);
    width: percentage(1 * 0.125);
    max-width: percentage(1 * 0.125);
  }
}

@include lg {
  .col-lg-1-1 {
    flex-basis: percentage(math.div(1, 1));
    width: percentage(math.div(1, 1));
    max-width: percentage(math.div(1, 1));
  }
  .col-lg-2-3 {
    flex-basis: percentage(math.div(2, 3));
    width: percentage(math.div(2, 3));
    max-width: percentage(math.div(2, 3));
  }
  .col-lg-1-3 {
    flex-basis: percentage(math.div(1, 3));
    width: percentage(math.div(1, 3));
    max-width: percentage(math.div(1, 3));
  }
  .col-lg-1-2 {
    flex-basis: percentage(1 * 0.5);
    width: percentage(1 * 0.5);
    max-width: percentage(1 * 0.5);
  }
  .col-lg-1-4 {
    flex-basis: percentage(1 * 0.25);
    width: percentage(1 * 0.25);
    max-width: percentage(1 * 0.25);
  }
  .col-lg-3-4 {
    flex-basis: percentage(3 * 0.25);
    width: percentage(3 * 0.25);
    max-width: percentage(3 * 0.25);
  }
  .col-lg-1-6 {
    flex-basis: percentage(math.div(1, 6));
    width: percentage(math.div(1, 6));
    max-width: percentage(math.div(1, 6));
  }
  .col-lg-1-5 {
    flex-basis: percentage(1 * 0.2);
    width: percentage(1 * 0.2);
    max-width: percentage(1 * 0.2);
  }
  .col-lg-2-5 {
    flex-basis: percentage(2 * 0.2);
    width: percentage(2 * 0.2);
    max-width: percentage(2 * 0.2);
  }
  .col-lg-3-5 {
    flex-basis: percentage(3 * 0.2);
    width: percentage(3 * 0.2);
    max-width: percentage(3 * 0.2);
  }
}

@include xl {
  .col-xl-1-1 {
    flex-basis: percentage(math.div(1, 1));
    width: percentage(math.div(1, 1));
    max-width: percentage(math.div(1, 1));
  }
  .col-xl-2-3 {
    flex-basis: percentage(math.div(2, 3));
    width: percentage(math.div(2, 3));
    max-width: percentage(math.div(2, 3));
  }
  .col-xl-1-3 {
    flex-basis: percentage(math.div(1, 3));
    width: percentage(math.div(1, 3));
    max-width: percentage(math.div(1, 3));
  }
  .col-xl-1-2 {
    flex-basis: percentage(1 * 0.5);
    width: percentage(1 * 0.5);
    max-width: percentage(1 * 0.5);
  }
  .col-xl-1-4 {
    flex-basis: percentage(1 * 0.25);
    width: percentage(1 * 0.25);
    max-width: percentage(1 * 0.25);
  }
  .col-xl-1-8 {
    flex-basis: percentage(1 * 0.125);
    width: percentage(1 * 0.125);
    max-width: percentage(1 * 0.125);
  }
  .col-xl-1-6 {
    flex-basis: percentage(math.div(1, 6));
    width: percentage(math.div(1, 6));
    max-width: percentage(math.div(1, 6));
  }
}
