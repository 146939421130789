body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert {
  // &-blur {
  //   filter: url(#gaussian-blur);
  //   filter: blur(1px);
  // }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(#000, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: react-confirm-alert-fadeIn 0.35s forwards;
  }

  &-body {
    max-width: 16rem;
    padding: var(--padding);
    text-align: center;
    background: #fff;
    border-radius: 6px;
    box-shadow:
      0 8px 10px -10px #00000060,
      0 0 4px #00000015;
    color: $dark;
    font-size: var(--font-size);
    color: rgba($dark, 0.6);

    &--large {
      max-width: calc(16rem + (var(--padding) * 2));
    }

    & > h1 {
      margin-top: 0;
      font-size: 1rem;
      color: $dark;
      margin-bottom: 1em;
    }

    & > h3 {
      margin: 0;
      font-size: 16px;
    }

    .preview {
      div {
        width: 100% !important;
        height: auto !important;
      }
    }

    textarea {
      width: 100%;
      border: 0;
      resize: none;
      height: 7rem;
      background: rgba($dark, 0.05);
      font-family: monospace;
      margin-top: 1em;
      padding: 1em;
      border: 1px solid rgba($dark, 0.1);
      border-radius: 5px;
    }
  }

  &-svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  &-button-group {
    display: flex;
    justify-content: center;
    margin: calc(var(--padding) * 0.75) -0.5em 0;

    & > button {
      display: inline-block;
      padding: 0.8em 1.6em;
      font-size: inherit;
      font-family: inherit;
      border: 1px solid rgba($dark, 0.2);
      border-radius: 2em;
      color: $purple;
      transition: all $speed $ease;
      cursor: pointer;
      margin: 0 0.5em;
      font-weight: 500;
      background: transparent;

      &:first-child {
        color: rgba($dark, 0.5);
      }
    }
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
