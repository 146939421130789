.component {
  font-family: var(--font-family, 'Basis', Arial, sans-serif);
  margin: var(--vpadding) auto;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    outline: 0;
  }
}
