.warning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $light;

  &-head {
    position: absolute;
    top: 0;
    width: 100%;
    height: 3rem;
    line-height: 3rem;
    margin-top: 0;
    text-align: center;
    font-family: $alt-font-family;
  }

  &-body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 24rem;
    padding: 2rem;
    background: #fff;
    border-radius: 6px;
    box-shadow:
      0 8px 10px -10px #00000060,
      0 0 4px #00000015;
    width: calc(100% - 2rem);
    color: $dark;
    //
    // &-mobile, &-desktop {
    //   display: none;
    // }
  }
  &--mobile &-body-mobile {
    display: block;
  }
  &--desktop &-body-desktop {
    display: block;
  }
}
