@import 'ui/styles/utilities';

$white: #fff;
$black: #000;
$light: #f9f8f8;
$light: #f7f5ff;
$dark: #140041;
$purple: #550eff;
$lightpurple: #ddd4ff;
$green: #2ee987;
$pink: #ff93c5;
$yellow: #fffdc9;
$orange: #ff8147;
$red: #ff4747;
$selection-color: $lightpurple;

// spacing
$padding: 1.5rem;

// typography
$font-family: var(--innovator), 'Helvetica', Arial, sans-serif;
$mono-font-family: var(--innovator), 'Helvetica', Arial, sans-serif;
$title-font-family: 'Fortescue', Times, serif;
$text-font-family: 'Charter', Times, serif;
$alt-font-family: 'Terrazzo', 'Helvetica', Arial, sans-serif;
$font-size: 20px;

// animations
$speed: 0.325s;
$ease: cubic-bezier(0.6, 0.6, 0, 1);

// responsive
$xs: 568px;
$sm: 768px;
$md: 800px;
$lg: 1300px;
$xl: 1450px;
$max-width: 1540px;

:root {
  --white: #{$white};
  --black: #{$black};
  --light: #{$light};

  --dark0125: #{rgba($dark, 0.0125)};
  --dark025: #{rgba($dark, 0.025)};
  --dark05: #{rgba($dark, 0.05)};
  --dark10: #{rgba($dark, 0.1)};
  --dark125: #{rgba($dark, 0.125)};
  --dark15: #{rgba($dark, 0.15)};
  --dark20: #{rgba($dark, 0.2)};
  --dark30: #{rgba($dark, 0.3)};
  --dark40: #{rgba($dark, 0.4)};
  --dark50: #{rgba($dark, 0.5)};
  --dark60: #{rgba($dark, 0.6)};
  --dark70: #{rgba($dark, 0.7)};
  --dark80: #{rgba($dark, 0.8)};
  --dark90: #{rgba($dark, 0.9)};

  --padding: 1.5rem;
  --nav-height: 4.5rem;
  --nav-width: 0%;

  --font-size: 0.8em;

  --font: #{$font-family};
  --font-settings: 'ss01', 'cv04';

  --font-mono: #{$mono-font-family};
  --font-mono-settings: 'ss01', 'ss02', 'cv04';
  --font-title: #{$title-font-family};
  --font-text: #{$text-font-family};
  --font-alt: #{$alt-font-family};

  --dark: #{$dark};
  --light: #{$light};
  --lightrgba: #{rgba(mix($light, $purple, 92.5%), 0.8)};
  --purple: #{$purple};
  --lightpurple: #{$lightpurple};
  --pink: #{$pink};
  --green: #{$green};
  --yellow: #{$yellow};
  --orange: #{$orange};
  --red: #{$red};
  --navbar-height: calc(var(--padding) * 1.33333 + (var(--font-size) * 1));

  --cs__width: 20em;
  --cs__height: 16.666em;

  @include md {
    --nav-width: clamp(20%, 15rem, 25%);
  }

  @include lg {
    // --nav-width: clamp(20%, 20em, 25%);
  }
}
