// @import 'styles.utilities';

$black: #000;
$white: #fff;
$white-slant: darken(#faf9f7, 1.25%);
$grey: #647275;
$lightgrey: #f8f6f3;
$blue: #2c7efc;
$darkmode: mix($black, $white, 97%);

$purple: #311d51;
$highlight: #6832ef;
$support: #f8f6f3;

// base
$font-size: 20px;
$selection-color: $highlight;
$font-color: $black;
$padding: 2rem;
$font-family: 'Basis', Arial, sans-serif;
$title-font-family: 'Fortescue', Times, serif;
$text-font-family: 'Charter', Times, serif;
$extra-font-family: 'Terrazzo', Arial, sans-serif;
$border-radius: 0.6em;

// sizes
$size-xs: 568px;
$size-md: 900px;
$size-lg: 1250px;
$size-xl: 1600px;

$max-width: 56rem;

// transition
$speed: 0.25s;
$ease: ease;
