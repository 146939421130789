@mixin underline($color, $height, $y, $shadow: false) {
  background-image: linear-gradient(transparent 50%, $color 50%);
  background-repeat: repeat-x;
  background-size: 1px $height;
  background-position: 0 $y;
  text-decoration: none;

  @if $shadow {
    text-shadow:
      1px 1px 0 $shadow,
      2px 1px 0 $shadow,
      -2px -1px 0 $shadow,
      -2px -2px 0 $shadow,
      -2px 1px 0 $shadow,
      -1px 1px 0 $shadow;
  }
}
